*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container-sidebar{
    display:flex;
    
}
main{
    width: 100%;
    padding: 2px;
}
.sidebar{
    background: #fff;
    color: #000;
    height: 'auto';
    width: 200px;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 20px;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #000;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.3s;
    text-decoration: none;
}
.link:hover{
    background:#E0DFDE;
    border-radius: 5px;
    color: #000;
    transition: all 0.3s;
}
.active{
    background: #E0DFDE;
    color: #000;
    border-radius: 5px;
}
.icon, .link_text{
    font-size: 14px;
}
