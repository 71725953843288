.row-between-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1px;
    padding: 1px;
}

.body-center-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 300px;
}

.row-page {
    display: flex;
    flex-direction: row;
    margin: 1px;
    padding: 1px;
}

.row-page-box {
    display: flex;
    flex-direction: row;
    margin: 1px;
    padding: 1px;
    width: 50%;
}

.form-body-page {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding: 10px;
    background-color: aliceblue;
    font-size: 12px;
    border-radius: 5px;
}

.form-body-page-pdf {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding: 10px;
    background-color: aliceblue;
    font-size: 14px;
    border-radius: 5px;
}

.form-body-login-page {
    display: flex;
    width: 100%;
    height: 750px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: aliceblue;
    font-size: 14px;
    border-radius: 5px;
}

.grad-login-form {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

/* .from-pdf-a4{
    display: flex;
    width: 210mm;
    height: 297mm;
} */

.end-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    /* background-color: antiquewhite; */
}

.border-green-page {
    /* border-width: 0.5px; */
    border: solid 1px green;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
}

.border-box-center {
    border: solid 1px green;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#DEE1E0;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    margin-left: -50%;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.header {
    position: sticky;
    top: 0;
    z-index: 999;
}

.box-body-color{
    background-color: #ffff;
    border-radius: 5px;
    padding: 5px;
}
.box-body-color:hover{
    background-color:#DEE1E0;
    border-radius: 5px;
}