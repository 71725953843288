.form-body{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    padding: 10px;
    background-color:aliceblue;
    font-size: 14px;
    border-radius: 5px;
}

.row-between-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1px;
    padding: 1px;
}

.row-form{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    margin: 1px;
    padding: 1px; 
}

table, th, td {
    border: 1px solid white;
    border-collapse: collapse;
  }
  th {
    background-color: #96D4D4;
  }
  td{
    padding-left: 5px;
  }

  .border_green {
    /* border-width: 0.5px; */
    border: solid 1px green;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
}